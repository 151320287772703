import React, { useRef, useEffect } from 'react'

/**
 * Custom React hook that fires a callback when a specified element is swiped on.
 * The hook returns a React reference that can be added to a component to enable the swipe callback.
 * 
 * @param {function} cb - Callback function for when the element is clicked.
 * @param {array} watch - Array of variables to watch.
 */
export function useSwipe(cb, watch = []) {
    // Create reference for later usage.
    const ref = useRef();
    // This code should only be executed when an element in the watch array is changed.
    useEffect(() => {
        // This swipe object will store all the data for any particular swipe.
        let swipe = {}
        // First of all, handle the mousedown event, save position and time.
        function mouseDown(e) {
            swipe = {};
            swipe.down = {
                x: e.x,
                y: e.y,
                time: e.timeStamp
            }
            if (e.changedTouches) {
                swipe.down = {
                    x: e.changedTouches[0].clientX,
                    y: e.changedTouches[0].clientY,
                    time: e.timeStamp
                }
            }
        }
        // When the mouse is released. Calculate the swipe.
        function mouseUp(e) {
            // Save 'raw' data.
            swipe.up = {
                x: e.x,
                y: e.y,
                time: e.timeStamp
            }
            // If this is a mobile the raw data looks a bit different.
            if (e.changedTouches) {
                swipe.up = {
                    x: e.changedTouches[0].clientX,
                    y: e.changedTouches[0].clientY,
                    time: e.timeStamp
                }
            }
            // Calculate distance (px).
            swipe.distance = Math.sqrt(Math.pow((swipe.down.x - swipe.up.x), 2) + Math.pow((swipe.down.y - swipe.up.y), 2));
            // Calculate the angle (deg).
            swipe.angle = (Math.atan2(swipe.up.y - swipe.down.y, swipe.up.x - swipe.down.x) * 180.0 / Math.PI) + 90;
            if (swipe.angle < 0) swipe.angle += 360;
            // Calculate the duration (ms).
            swipe.duration = swipe.up.time - swipe.down.time;
            // Get the direction as a string for ease of use.
            if (swipe.angle > 45 && swipe.angle <= 135) swipe.direction = 'right';
            else if (swipe.angle > 135 && swipe.angle <= 215) swipe.direction = 'down';
            else if (swipe.angle > 215 && swipe.angle <= 305) swipe.direction = 'left';
            else swipe.direction = 'up';
            // Check if this is a swipe or not.
            // These values can be changed to modify the swipe 'feel'. 
            if (
                (swipe.duration > 100 && swipe.duration < 400) &&
                (swipe.distance > 50)
            ) {
                // Execute callback with the swipe as an argument.
                cb(swipe)
            }
        }
        // Setup listeners with the functions above. (Remove mousedown and up events to enable swipe on desktop)
        ref.current.addEventListener('mousedown', mouseDown);
        ref.current.addEventListener('mouseup', mouseUp);
        ref.current.addEventListener('touchstart', mouseDown);
        ref.current.addEventListener('touchend', mouseUp);
        // Cleanup function.
        return () => {
            ref.current.removeEventListener('mousedown', mouseDown);
            ref.current.removeEventListener('mouseup', mouseUp);
            ref.current.removeEventListener('touchstart', mouseDown);
            ref.current.removeEventListener('touchend', mouseUp);
        }
    }, [...watch]);
    // Return the reference which will be used to define on which element this swipe should be applied on.
    return ref;
}


