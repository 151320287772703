import React, { useState } from 'react'


const CookieBanner = () => {

    let c = localStorage.getItem('accepted_cookie');
    const [visible, setVisible] = useState((c && c == 'true') ? false : true);

    if (!visible) return <></>;
    return (
        <div className="cookie-banner">
            <p>We use cookies to help give you the best experience on our site. By
                continuing you agree to our use of cookies. </p>
            <div className="button" onClick={() => {
                localStorage.setItem('accepted_cookie', 'true')
                setVisible(false);
            }}>
                Accept and close
            </div>
        </div>
    )
}

export default CookieBanner
