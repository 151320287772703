import React, { useState, useEffect } from 'react'
import './hero.scss'
import Heading from '../../../../Components/Heading/Heading'
import { useDevices } from '../../../../utils/Breakpoints'
import Base from '../../../../styles/base.scss'
import Button from '../../../../Elements/Button/Button'
import { Link } from 'react-router-dom'
import Wordpress from '../../../../Wordpress'


const Hero = ({ block }) => {

    const [mobile, tablet, desktop] = useDevices();
    return (
        <>

            {block.background_image &&
                <img src={block.background_image.sizes['2048x2048']} className="bg_image"></img>}
            {block && block.logo &&
                <div className="hero-logo">
                    <Link to={'/'}><img src={block.logo.sizes.medium}></img></Link>
                </div>
            }
            <div className="hero-social">
                {!mobile && block && block.social_media && block.social_media.map((media, key) => (
                    <a href={media.link.url} target="_blank">
                        <img src={media.icon.sizes.medium}></img>
                    </a>
                ))}
            </div>

            <Heading heading={block.heading} lead={block.lead} h_color={block.font_color} l_color={Base.white} position={block.reversed ? 'right' : 'left'} />

            <div className="hero-buttons">
                {block.buttons && block.buttons.map((b, i) => {
                    if (b.link.url.includes(Wordpress.getURL()))
                        return <Button key={i} text={b.text} url={b.link.url.replace(Wordpress.getURL(), '')} style={b.style} size='small' />
                    else
                        return <a key={i} className={`button ${b.style} big`} href={b.link.url} target="_blank">{b.text}</a>
                })}
            </div>


            {block.featured_image && <img
                src={block.featured_image.sizes.large}
                className={`ft-image ${block.reversed} `} >
            </img>}
        </>
    )
}

export default Hero;
