import React, { useState, useEffect } from 'react'
import './gallery.scss'
import Base from './../../../../styles/base.scss'
import Button from './../../../../Elements/Button/Button'
import ArrowRight from '../../../../media/images/gallery_arrow_right.png'
import { useSwipe } from '../../../../utils/Swipe';
import { useDevices } from '../../../../utils/Breakpoints'

const Gallery = ({ block }) => {
    const [currentSlide, setCurrent] = useState(0);
    const [mobile, tablet, desktop] = useDevices();

    const ref = useSwipe((swipe) => {
        if (swipe.direction == 'right' || swipe.direction == 'left')
            next();
    }, [currentSlide])

    const next = () => {
        if (currentSlide + 1 >= block.slides.length) setCurrent(0)
        else setCurrent(currentSlide + 1)
    }

    const previous = () => {
        if (currentSlide - 1 < 0) setCurrent(block.slides.length - 1)
        else setCurrent(currentSlide - 1)
    }

    return (

        <div className="gallery-container" ref={ref}>
            {block && block.slides.map((slide, index) => (
                <div className={`gallery-slide ${index == currentSlide ? 'current' : ''}`} style={{ backgroundColor: slide.background_color }}>
                    <img src={slide.image.sizes['2048x2048']} alt={slide.image.alt} />
                    <div className="gallery-content">
                        <div className="gallery-content-wrapper">
                            <div className="gallery-heading">
                                <h1 dangerouslySetInnerHTML={{ __html: slide.heading }} style={{ color: slide.heading_color }}></h1>
                                <h4 style={{ color: Base.white }}>{slide.lead}</h4>
                            </div>
                            <div className="lower-content">
                                <div className="gallery-button">
                                    {slide.button && slide.button.map(b => (
                                        <Button text={b.text} url={b.link.url} style={b.style} size='small' />
                                    ))}
                                </div>
                                <img src={slide.icon.sizes.medium} className="gallery-icon"></img>
                            </div>
                        </div>
                    </div>
                </div>
            ))}

            <div className="gallery-nav">
                {block && block.slides.map((slide, index) => (
                    <div className={index == currentSlide ? 'current' : ''} onClick={() => setCurrent(index)}></div>
                ))}
            </div>
        </div>

    )
}

export default Gallery
