import React, { useState, useEffect, useRef } from 'react'
import './products.scss'
import S from '../../../../media/images/search.svg'
import Button from '../../../../Elements/Button/Button';
import { useDevices } from '../../../../utils/Breakpoints'
import Base from './../../../../styles/base.scss'
import { useParams, useLocation } from 'react-router';
import Heading from '../../../../Components/Heading/Heading'
import { Link } from 'react-router-dom'
import HalfHero from '../HalfHero/HalfHero';
import ScrollToTop from '../../../../utils/ScrollToTop';
import { findAllByPlaceholderText } from '@testing-library/react';


const Products = ({ block }) => {


    let location = useLocation();
    const topRef = useRef();






    let getParams = location.search.substring(1).split('&').map(kp => kp.split('='));

    let defaultCountry = getParams.find(p => p[0] == 'country');
    if (defaultCountry) defaultCountry = defaultCountry[1];

    let defaultCategory = getParams.find(p => p[0] == 'category');
    if (defaultCategory) defaultCategory = defaultCategory[1];


    let perPage = 12;

    const [mobile, tablet, desktop] = useDevices();
    let products = block.product;
    let categories = [];
    let countries = [];

    const [product, setProduct] = useState()

    useEffect(() => {
        setTimeout(() => {
            topRef.current.scrollTo(0, 0)
        }, 0)
    }, [product])



    useEffect(() => {
        if (defaultCategory || defaultCountry) window.scrollTo(0, window.innerHeight / 2)
    }, [])
    const [category, setCategory] = useState(defaultCategory ? { value: defaultCategory } : undefined)

    const [country, setCountry] = useState(defaultCountry ? { value: defaultCountry } : undefined)
    const [search, setSearch] = useState()
    const [page, setPage] = useState(1)

    products.forEach(p => {
        if (p.categories) p.categories.forEach(c => { if (!categories.find(cc => cc.label == c.label)) categories.push(c) })
        if (p.countries) p.countries.forEach(c => { if (!countries.find(cc => cc.label == c.label)) countries.push(c) })
    })

    // Filter products by category and search string.
    let filteredProducts = products.filter(p => {

        let match = false;
        let hasCategory = p.categories != '' && p.categories.find(c => c.value == (category && category.value)) != undefined;
        let hasCountry = p.countries != '' && p.countries.find(c => c.value == (country && country.value)) != undefined;

        // Om inget filter är valt:
        if (country == undefined && category == undefined) match = true;
        
        // Om båda filtren är valda
        if ((country != undefined && category != undefined)) {
            
            // Både kategori och land stämmer.
            if (hasCountry && hasCategory) match = true;

        } else {

            // Antingen kategori eller land stämmer.
            if (hasCountry || hasCategory) match = true;
        }
        

        if (search) {
            match = false;
            if (p.heading.toLowerCase().includes(search.toLowerCase())) match = true;
            if (p.sub_heading.toLowerCase().includes(search.toLowerCase())) match = true;
        }

        return match;
    });



    return (

        <>

            <section className="block half_hero">
                <HalfHero block={{
                    background_image: category && category.value == 'delia' ? block.background_image_delia : block.background_image,
                    heading: category && category.value == 'delia' ? block.heading_delia : block.heading,
                    lead: category && category.value == 'delia' ? block.lead_delia : '',
                    logo: block.logo,
                    social_media: block.social_media,
                    font_color: block.font_color
                }} />
            </section>
            {category && category.value == 'delia' &&
                <div className="delia-section">
                  <div className="delia-text">
                    <div dangerouslySetInnerHTML={{ __html: block.lead_delia }} className="delia-lead"></div>
                  </div>
                  <div className="delia-image">
                      <img src={block.delia_logo.sizes.medium}></img>
                  </div>
                </div>

            }

            <div className="searchfield-section">
              

                <div className="filters">

                    {!mobile && (
                        <>
                            <div className="countries">
                                {countries && countries.map((c, index) => (
                                    <Button
            
                                        key={index}
                                        onClick={() => {
                                            setPage(1)
                                            if (country && country.value == c.value) setCountry(undefined)
                                            else setCountry(c)
                                        }}

                                        text={c.label}
                                        style={`outlined ${country && country.value == c.value ? 'active' : ''}`}
                                        size='small'
                                    />

                                ))}


                            </div>
                            <div className="categories">
                                {categories && categories.map((c, index) => {
                                    return <Button
                                        key={index}
                                        onClick={() => {
                                            setPage(1)
                                            if (category && category.value == c.value) setCategory(undefined)
                                            else setCategory(c)
                                        }}
                                        text={c.label}
                                        style={`outlined ${c.value === 'delia' && 'delia-button'} ${category && category.value == c.value ? 'active' : ''}`}
                                        size='small'
                                    />
                                })}
                            </div>
                        </>
                    )}

                    {mobile && (
                        <>
                            <select className="countries" value={country ? country.value : 'undefined'} onChange={(e) => {
                                setCountry({
                                    value: e.nativeEvent.target.selectedOptions[0].value
                                })
                            }}>
                                <option value="undefined">Välj land...</option>
                                {countries.map((c, id) => (
                                    <option value={c.value}>{c.label}</option>
                                ))}
                            </select>

                            <select className="categories" value={category ? category.value : 'undefined'} onChange={(e) => {
                                setCategory({
                                    value: e.nativeEvent.target.selectedOptions[0].value
                                })
                            }}>
                                <option value="undefined">Välj kategori...</option>
                                {categories.map((c, id) => (
                                    <option value={c.value}>{c.label}</option>
                                ))}
                            </select>
                        </>
                    )}
                </div>

                <div className="search-wrapper">
                    <input
                        className="search"
                        type="text"
                        value={search}
                        onChange={(e) => {
                            setPage(1)
                            setCategory(undefined)
                            setCountry(undefined)
                            setSearch(e.target.value)
                        }}
                        style={{ backgroundImage: `url(${S}`, backgroundSize: "1.5em", backgroundPosition: "90%" }}
                        placeholder="Sök efter produkter"
                    />
                </div>

            </div>


            <div className="products">


                {filteredProducts.map((product, index) => {

                    if (index < (page - 1) * perPage || index >= page * perPage) return;


                    return (
                        <article key={index} onClick={() => {
                            setProduct(product);
                        }}>
                            <img src={product.product_image.sizes.large} alt="product image" />
                            <h3>{product.heading}</h3>
                            <h5>{product.sub_heading}</h5>
                        </article>
                    )
                })}

                {filteredProducts.length == 0 && (
                    <div className="no-products">
                        <h2>Vi kunde inte hitta några produkter. Vänligen ändra ditt sökval.</h2>
                    </div>
                )}

            </div>

´           <section className={`product-wrapper ${product ? 'visible' : 'hidden'}`} onClick={(e) => {


                if (e.target.classList.contains('product-wrapper')) setProduct(undefined);


            }}>
                <div className="current_product">
                    <div className="left-section"><img src={product && product.product_image.sizes.large} alt="product image" /></div>
                    <div ref={topRef} className="right-section" style={{ backgroundColor: Base.dark_green }}>

                        {product && product.product_logo && (
                            <img src={product.product_logo.sizes.large} alt="protected logo" />
                        )}
                        <p dangerouslySetInnerHTML={{ __html: product && product.product_info }}></p>
                        <div onClick={() => setProduct(undefined)} className="x"></div>
                    </div>
                </div>
            </section>

            <div className="pagination-container">
                {(() => {
                    let buttons = [];
                    for (let i = 1; i <= Math.ceil(filteredProducts.length / perPage); i++) {
                        buttons.push(
                            <button
                                key={i}
                                className={`slides_button ${page == i ? 'active_page' : ''}`}
                                onClick={() => {
                                    setPage(i);
                                    window.scrollTo(0, window.innerWidth / 4);
                                }}
                            >{i}</button>)
                    }
                    return buttons;
                })()}
            </div>
        </>
    )
}


export default Products