import Axios from 'axios'

const url = 'https://foodcompetence.se/wp'

export default class Wordpress {

    static getURL() {
        return url;
    }

    static async checkCached(ep) {
        let json = await (await fetch(`${url}/wp-json${ep}`)).json();
        if (JSON.stringify(json) === localStorage.getItem(ep)) {
            // console.log(`${ep} is up to date!`);
        } else {
            console.log(`${ep} is outdated!`);
            localStorage.setItem(ep, JSON.stringify(json));
        }
    }

    static async get(ep) {
        if (!url) return;
        let cached = localStorage.getItem(ep);
        this.checkCached(ep);
        if (cached) return JSON.parse(cached);
        let json = await (await fetch(`${url}/wp-json${ep}`)).json();
        // localStorage.setItem(ep, JSON.stringify(json));
        return json;
    }

}
