import React from 'react'
import './halfHero.scss'
import Heading from '../../../../Components/Heading/Heading'
import { Link } from 'react-router-dom'
import { useDevices } from '../../../../utils/Breakpoints'



function HalfHero({ block }) {

    const [mobile, tablet, desktop] = useDevices();




    return (
        <>
            {block.background_image && <img
                src={block.background_image.sizes['2048x2048']}
                className="bg_image">
            </img>}
            <Heading heading={block.heading} h_color={block.font_color} position="left halfhero" />

            {block && block.logo &&
                <div className="half-hero-logo">
                    <Link to={'/'}><img src={block.logo.sizes.medium}></img></Link>
                </div>
            }
            <div className="half-hero-social">
                {!mobile && block && block.social_media && block.social_media.map((media, key) => (
                    <a href={media.link.url} target="_blank">
                        <img src={media.logo.sizes.thumbnail}></img>
                    </a>
                ))}
            </div>
        </>
    )
}

export default HalfHero
