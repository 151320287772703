import React, { useState, useEffect } from 'react'
import './page.scss'
import Axios from 'axios'
import Hero from './Blocks/Hero/Hero'
import Footer from './Blocks/Footer/Footer'
import Splitsection from './Blocks/Splitsection/Splitsection'
import Gallery from './Blocks/Gallery/Gallery'
import Contact from './Blocks/Contact/Contact'
import HalfHero from './Blocks/HalfHero/HalfHero'
import Products from './Blocks/Products/Products'
import Wordpress from '../../Wordpress'
import CookieBanner from './Blocks/CookieBanner/CookieBanner'
import ScrollToTop from '../../utils/ScrollToTop'
import ReactGA from 'react-ga';

const Startpage = (props) => {
    let slug;
    if (props && props.match && props.match.params) slug = props.match.params.slug;
    else if (props) slug = props.slug;

    const [page, setPage] = useState()

    useEffect(() => {
        (async () => {
            let pages = await Wordpress.get(`/wp/v2/pages`);
            let page = pages.find(p => p.slug == slug);

            if (!page) setPage(pages.find(p => p.slug == 'startpage'))
            else setPage(page)
        })()
    }, [slug])


    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, [page])




    return (
        <section className='blocks'>
            {page && page == '404' && (
                <h1>404 not found</h1>
            )}
            {page && page.acf && page.acf.blocks && page.acf.blocks.map((block, key) => (

                <section style={{ backgroundColor: block.background_color }} className={`block ${block.acf_fc_layout}`}>
                    {(() => {
                        if (block.acf_fc_layout == 'hero') return <Hero block={block} />
                        if (block.acf_fc_layout == 'split_section') return <Splitsection block={block} />
                        if (block.acf_fc_layout == 'footer') return <Footer block={block} />
                        if (block.acf_fc_layout == 'gallery') return <Gallery block={block} />
                        if (block.acf_fc_layout == 'contact') return <Contact block={block} />
                        if (block.acf_fc_layout == 'single_product') return <Products block={block} />
                        if (block.acf_fc_layout == 'half_hero') return <HalfHero block={block} />
                    })()}

                </section>

            ))}
            <CookieBanner />

        </section >

    )
}

export default Startpage;