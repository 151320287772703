import React from 'react'
import './heading.scss';

const Heading = ({ heading, lead, h_color, l_color, position, reversed, text }) => {
    return (
        <>
            <div className={`heading ${position} ${reversed}`}>
                <h1 dangerouslySetInnerHTML={{ __html: heading }} style={{ color: h_color }}></h1>
                <h4 style={{ color: l_color }}>{lead}</h4>
            </div>

        </>
    )
}

export default Heading
