import React from 'react'
import './button.scss'
import { Link } from 'react-router-dom'

export default function Button({ text, url, onClick, style = 'primary', size = 'big' }) {
    return (
        <Link to={url} onClick={onClick} className={`button ${style} ${size}`}>
            {text}
        </Link>
    )
}
