import React from 'react'
import './contact.scss';

const Contact = ({ block }) => {
    console.log(block)

    return (
        <>
            <div className="contact-text-section">
                <p className="contact-text">{block.contact_text}</p>
            </div>
            {block && block.contact && block.contact.map((obj, key) => (
                <div className="contact-section">
                    <a href={`mailto:${obj.email.split(' ')[1]}`}>{obj.email}</a>
            <a href={`tel:${obj.phone}`}><p>{obj.phone}</p></a>

                </div>
            ))}

            {block && block.icons && block.icons.map((icon, key) => (
                <div className="icon-section">
                    <img src={icon.icon.sizes.large} alt="" className="" />
                    <p>{icon.icon_information}</p>
                </div>
            ))}

        </>
    )
}

export default Contact
