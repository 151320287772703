import React, { useEffect } from 'react';
import './App.scss'
import ReactGA from 'react-ga';

import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Page from './pages/Page/Page'
import CompJS from './utils/Breakpoints'
import Navbar from './pages/Page/Blocks/Navbar/Navbar'
import Footer from './pages/Page/Blocks/Footer/Footer'
import ScrollToTop from './utils/ScrollToTop';




function App() {

  useEffect(() => {

    CompJS.init();
    ReactGA.initialize('UA-167907185-15');
  }, [])



  return (
    <div className="app">
      <Router onUpdate={() => window.scrollTo(0, 0)}>
        <ScrollToTop />
        <Navbar />

        <Switch>
          <Route exact path="/:slug" component={Page}></Route>
          <Route exact path="/produkter"> <Page slug="produkter" /> </Route>
          <Route exact path="/"> <Page slug="startpage" /> </Route>
        </Switch>

        <Footer />
      </Router>
    </div>

  )
}

export default App;
