import React, { useLayoutEffect, useState } from 'react';

/**
 * Define global settings such as breakpoints etc.
**/

let settings = {
    breakpoints: {
        mobile: {
            width: 500,
            aspectRatio: 0.7
        },
        tablet: {
            width: 1200,
            aspectRatio: 1.0
        },
    },
    scale: {
        mobile: 3.0,
        tablet: 2.0
    }
}
let runtime = {
    mobile: false,
    tablet: false,
    desktop: false
}

function getBreakpoint(width, height) {
    let asp = width / height;
    runtime.mobile = false;
    runtime.tablet = false;
    runtime.desktop = false;
    if (width !== 0 && width < settings.breakpoints.mobile.width || asp < settings.breakpoints.mobile.aspectRatio) {
        runtime.mobile = true;
    } else if (width !== 0 && (width < settings.breakpoints.tablet.width || asp < settings.breakpoints.tablet.aspectRatio) && asp < 1.0) {
        runtime.tablet = true;
    } else if (width !== 0 && width > settings.breakpoints.tablet.width || asp > settings.breakpoints.tablet.aspectRatio) {
        runtime.desktop = true;
    }
    return [runtime.mobile, runtime.tablet, runtime.desktop]
}

/**
 * Get the window size as a react hook.
 */

export function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}
/**
 * React hook that will define mobile, tablet and desktop booleans to be used
 * in components as a method to change the structure depending on the current
 * device screen size. Uses the breakpoints defined in the settings object.
 * 
 * Return format: [mobile, tablet, desktop]
 */
export function useDevices() {
    const [width, height] = useWindowSize();
    return getBreakpoint(width, height);
}

export default class CompJS {
    /**
     * Inits the CompJS library.
     */
    static init() {
        /**
         * Update the font-size by using a custom resize function.
         */
        let resize = function (e) {
            let x = window.innerWidth;
            getBreakpoint(window.innerWidth, window.innerHeight);
            let w = 1800;		// "Width" / duration for the change to happen.
            let bp = 1250;		// Change m -> 0
            let s = 1;
            let scale = 1.20;
            s = (Math.sin(x * (Math.PI / w) - (Math.PI / 2) * ((bp / w * 2) - 1)) / 4) + 0.75;
            if (runtime.mobile) s = settings.scale.mobile;
            else if (runtime.tablet) s *= settings.scale.tablet;
            else if (runtime.desktop) s = 0.85;

            if (x > bp + w) s = 0.5;
            document.body.style.fontSize = (s * scale) + 'vw';
            document.body.classList.remove('desktop')
            document.body.classList.remove('tablet')
            document.body.classList.remove('mobile')
            if (runtime.mobile) document.body.classList.add('mobile')
            if (runtime.tablet) document.body.classList.add('tablet')
            if (runtime.desktop) document.body.classList.add('desktop')
        }
        window.removeEventListener('resize', () => { })
        window.addEventListener('resize', resize)
        resize();
    }
}