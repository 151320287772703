import React, { useEffect } from 'react'
import { useLocation } from "react-router-dom";
import { useDevices } from '../utils/Breakpoints'


export default function ScrollToTop() {
    const { pathname } = useLocation();
    const [mobile, tablet, desktop] = useDevices();


    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
}