import React, { useState, useEffect } from 'react'
import './navbar.scss'
import MouseIcon from '../../../../media/images/navbar_icon.png'
import MenuIcon from '../../../../media/images/menu.png'
import Axios from 'axios'
import { Link } from 'react-router-dom'
import MouseTrace from '../../../../media/images/mouse_pattern.png'
import Wordpress from '../../../../Wordpress'

const Navbar = () => {

    const [navlinks, setNavlinks] = useState()
    const [visible, setVisibility] = useState(false);

    useEffect(() => {
        (async () => setNavlinks(await Wordpress.get(`/myroutes/menu`)))()
    }, [])

    return (
        <>


            <img src={MenuIcon} alt="menu icon" onClick={() => { setVisibility(!visible) }} className={visible ? 'menu-icon closed' : 'menu-icon'}></img>
            <div className={`navbar ${visible ? 'open' : 'closed'}`}>
                <div className="upper-section">
                    <img src={MouseIcon} alt="mouse icon" className="icon" />
                    <a onClick={() => { setVisibility(!visible) }} className="x"></a>
                    <div className="contact-section">
                        <p className="">info@foodcompetence.se</p>
                        <p className="">08-679 66 66</p>
                        <p className="">Öppettider: Mån - Fre 08:00-17:00</p>
                    </div>
                </div>
                <div className="bottom-section">
                    {navlinks && navlinks.map(link => {

                        if (link.url.includes(Wordpress.getURL())) return <Link to={link.url.replace(Wordpress.getURL(), '')} onClick={() => setVisibility(false)}>{link.title}</Link>;
                        else return <a href={link.url} target="_blank" onClick={() => setVisibility(false)}>{link.title}</a>;

                    })}
                    <img src={MouseTrace} alt="mouse leaving a trace" className="bottom-image" />
                </div>
            </div>
            <div className={`overlay ${visible ? 'open' : 'closed'}`} onClick={() => setVisibility(false)}></div>
        </>
    )
}

export default Navbar
