import React from 'react'
import './splitsection.scss'
import Heading from '../../../../Components/Heading/Heading'
import Base from '../../../../styles/base.scss'


export const Splitsection = ({ block }) => {
    { console.log(block) }

    return (

        <>
            {block && block.heading && (
                <Heading heading={block.heading} lead={block.lead} h_color={Base.white} position="center" l_color={Base.white} />)}
            <div className="split">
                {block.left_image &&
                    <img src={block.left_image.sizes.large} className="left-image"></img>}
                {block.right_image &&
                    <img src={block.right_image.sizes.large} className="right-image"></img>}
            </div>
        </>
    )
}

export default Splitsection;