import React, { useState, useEffect } from 'react'
import './footer.scss'
import Axios from 'axios'
import Wordpress from '../../../../Wordpress'
import { Link } from 'react-router-dom'

export const Footer = () => {

    const [page, setPage] = useState()

    useEffect(() => {
        (async () => setPage((await Wordpress.get(`/acf/v3/options/footer`)).acf.blocks))()
    }, [])

    return (
        <footer className="footer">
            {page && page.map((obj, key) => (
                <>
                    <div className="site-links">
                        <h4>Information</h4>
                        {obj.links.map((links, key) => {
                            if (links.link.url == undefined) return;
                            if (links.link.url.includes(Wordpress.getURL())) return <Link to={links.link.url.replace(Wordpress.getURL(), '')}>{links.link.title}</Link>;
                            else return <a href={links.link.url} target="_blank">{links.link.title}</a>;
                     
                        })}
                    </div>

                    <div className="contact-info">
                        <h4>Kontakt</h4>
                        {obj && obj.contact.map((info, key) => (
                            <>
                                <a href={`mailto:${info.epost}`}>{info.epost}</a>
                                <a href={`tel:${info.phone}`}><p>{info.phone}</p></a>
                                <p>{info.hours}</p>
                            </>
                        ))}
                    </div>

                    <div className="logo">
                        <Link to={'/'}><img src={obj.logo.sizes.medium}></img></Link>
                    </div>

                    <div className="social">
                        {obj && obj.social_media.map((media, key) => (
                            <a href={media.link.url} target="_blank">
                                <img src={media.icon.sizes.thumbnail}></img>
                            </a>
                        ))}
                    </div>

                </>
            ))
            }
        </footer >
    )
}

export default Footer;
